import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Header } from "./Header";

// 特定のエンドポイントへのリクエスト送信用ライブラリとしてaxiosを使用
import { requests, instance } from "./axios";

export const Detail = () => {

  const params          = useParams();
  const book_id         = params.book_id;
  const request_detail  = '/' + book_id;        // 詳細データ取得用URL
  const request_edit    = '/edit/' + book_id;   // 更新用URL
  const request_delete  = '/delete/' + book_id; // 削除用URL
  const navigate        = useNavigate();        // 別ページ遷移用

  // 書籍データはStateで保持
  const [ book, setBook ] = useState({
    title: '',
    author: '',
    publisher: '',
    year: '',
    genre: '',
    isbn: '',
    title_kana: '',
    unknown: '',
  });

  // axiosで書籍データを取得し、setBookで書籍データをStateに保持
  useEffect(() => {
    instance.get(request_detail)
      .then((response) => { setBook(response.data); })
      .catch(error => console.log(error));
  }, []);

  // 共通化したState更新処理
  const handleChange = ( e: ChangeEvent ) => {
    const { name, value } = e.target
    setBook({ ...book, [name]: value })
  }

  // 書籍データの修正
  const editBook = () => {
    const bookdata = {
      title:        book.title, 
      author:       book.author, 
      publisher:    book.publisher, 
      year:         book.year, 
      genre:        book.genre, 
      isbn:         book.isbn, 
      unknown:      book.unknown,
      title_kana:   book.title_kana, 
      id:           book.id
    };

    instance.put(request_edit, bookdata)
    .then(() => navigate('/list'))  // 更新が成功したら一覧ページへ
    .catch(error => { console.log(error.response); });
  }

  // 書籍データの削除
  const deleteBook = () => {
    instance.delete(request_delete)
    .then(() => navigate('/list'))  // 削除が成功したら一覧ページへ
    .catch(error => { console.log(error.response); });
  }

  const DetailTopStyle = {marginTop:"1em",}

  return (
    <>
    <Header detail="active"/>

    <main role="main">
      <div className="container viewpage">
        <h1>詳細表示</h1>
  
        <form method="post" action="">
        <table className="table table-condensed" style={DetailTopStyle}>
          <thead>
          <tr><th>項目</th><th>値</th></tr>
          </thead>
          <tbody>
          <tr><td>ID</td><td>{book_id}</td></tr>
          <tr><td>書籍名</td><td><input type="text" name="title" maxLength="64" className="form-control" required id="id_title" onChange={handleChange} value={book.title}/></td></tr>
          <tr><td>書籍カナ</td><td><input type="text" name="title_kana" maxLength="64" className="form-control" required id="id_title_kana" onChange={handleChange} value={book.title_kana}/></td></tr>
          <tr><td>著者名</td><td><input type="text" name="author" maxLength="40" className="form-control" id="id_author" onChange={handleChange} value={book.author} /></td></tr>
          <tr><td>出版社</td><td><input type="text" name="publisher" maxLength="64" className="form-control" id="id_publisher" onChange={handleChange} value={book.publisher} /></td></tr>
          <tr><td>発行年</td><td><input type="number" name="year" className="form-control" id="id_year" onChange={handleChange} value={book.year} /></td></tr>
          <tr><td>分類ID</td><td><input type="text" name="genre" maxLength="12" className="form-control" id="id_genre" onChange={handleChange} value={book.genre} /></td></tr>
          <tr><td>ISBN</td><td><input type="text" name="isbn" maxLength="18" className="form-control" id="id_isbn" onChange={handleChange} value={book.isbn} /></td></tr>
          <tr><td>整理ID</td><td><input type="text" name="unknown" maxLength="14" className="form-control" id="id_unknown" onChange={handleChange} value={book.unknown} /></td></tr>
          </tbody>
        </table>
        <div className="row">
          <div className="col-auto"><button className="btn btn-outline-warning my-2 my-sm-0" type="button" onClick={editBook}>修正</button></div>
          <div className="col-auto"><button className="btn btn-outline-danger my-2 my-sm-0" type="button" onClick={deleteBook}>削除</button></div>
        </div>
        </form>
      </div>
    </main>
    </>
  )
}

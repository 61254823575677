import { useState, useContext } from "react";
import { useNavigate, useIsFocused } from "react-router-dom";
import { SearchStrContext } from "./providers/SearchStrProvider";

export const Header = (props) => {

  const navigate = useNavigate();


  // 検索文字列はuseStateで取得
  const [ inputStr, setInputStr ] = useState("");

  // Context内のsearchStrと更新関数を取得
  const { searchStr, setSearchStr } = useContext(SearchStrContext);

  // 検索文字列入力時のState更新処理
  const searchChange = ( e: ChangeEvent ) => {
    const { name, value } = e.target;
    setInputStr(value); // inputStrに検索欄に入力された値が格納される
  }

  // 書籍データ検索
  const execSearch = () => {
    setSearchStr(inputStr); // searchStrコンテキストに検索文字列が格納される
    navigate('/exec_search');   // 強制的にページ遷移させる
  }

  return (
    <>
    <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <a className="navbar-brand" href="/">booklist03</a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarsExampleDefault">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item" className={props.index}>
            <a className="nav-link" href="/">Index</a>
          </li>
          <li className="nav-item" className={props.list}>
            <a className="nav-link" href="/list">List</a>
          </li>
          <li className="nav-item" className={props.detail}>
            <a className="nav-link" href="/detail/10">Detail</a>
          </li>
          <li className="nav-item" className={props.add}>
            <a className="nav-link" href="/add">Add</a>
          </li>
        </ul>
        <form className="form-inline my-2 my-lg-0">
          <input className="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search" name="search" id="id_search" onChange={searchChange} value={inputStr}/>
          <button className="btn btn-outline-success my-2 my-sm-0" type="button" onClick={execSearch}>Search</button>
        </form>
      </div>
    </nav>
    </>
  );
}

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from './Header';

// 特定のエンドポイントへのリクエスト送信用ライブラリとしてaxiosを使用
import { requests, instance } from "./axios";

export const Add = () => {

  const request_add = '/add';
  const navigate    = useNavigate();

  // 書籍データはStateで保持
  const [ book, setBook ] = useState({
    title: '',
    author: '',
    publisher: '',
    year: '',
    genre: '',
    isbn: '',
    title_kana: '',
    unknown: '',
  });

  // 共通化したState更新処理
  const handleChange = ( e: ChangeEvent ) => {
    const { name, value } = e.target
    setBook({ ...book, [name]: value })
  }

  // 書籍データ追加
  const addBook = () => {
    const bookdata = {
      title:        book.title,
      author:       book.author,
      publisher:    book.publisher,
      year:         book.year,
      genre:        book.genre,
      isbn:         book.isbn,
      unknown:      book.unknown,
      title_kana:   book.title_kana,
      id:           book.id
    };

    instance.put(request_add, bookdata)
    .then(() => navigate('/list'))  // 追加が成功したら一覧ページへ
    .catch(error => { console.log(error.response); });
  }

  return (
    <>
    <Header add="active"/>

    <main role="main">

      <div class="container viewpage">
        <h1>新規追加</h1>
        <form>
        <table class="table table-condensed">
          <tr><th>項目</th><th>値</th></tr>
          <tr><td>書籍名</td><td><input type="text" name="title" maxlength="64" class="form-control" required id="id_title" onChange={handleChange} value={book.title} /></td></tr>
          <tr><td>書籍カナ</td><td><input type="text" name="title_kana" maxlength="64" class="form-control" required id="id_title_kana" onChange={handleChange} value={book.title_kana} /></td></tr>
          <tr><td>著者名</td><td><input type="text" name="author" maxlength="40" class="form-control" id="id_author" onChange={handleChange} value={book.author} /></td></tr>
          <tr><td>出版社</td><td><input type="text" name="publisher" maxlength="64" class="form-control" id="id_publisher" onChange={handleChange} value={book.publisher} /></td></tr>
          <tr><td>発行年</td><td><input type="number" name="year" class="form-control" id="id_year" onChange={handleChange} value={book.year} /></td></tr>
          <tr><td>分類ID</td><td><input type="text" name="genre" maxlength="12" class="form-control" id="id_genre" onChange={handleChange} value={book.genre} /></td></tr>
          <tr><td>ISBN</td><td><input type="text" name="isbn" maxlength="18" class="form-control" id="id_isbn" onChange={handleChange} value={book.isbn} /></td></tr>
          <tr><td>整理ID</td><td><input type="text" name="unknown" maxlength="14" class="form-control" id="id_unknown" onChange={handleChange} value={book.unknown} /></td></tr>
        </table>
        <button class="btn btn-outline-primary my-2 my-sm-0" type="button" onClick={addBook}>追加</button>
        </form>
      </div>
    </main>
    </>
  )
}

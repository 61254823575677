import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { Index  } from './components/Index';
import { List   } from './components/List';
import { Search } from './components/Search';
import { Detail } from './components/Detail';
import { Add    } from './components/Add';

export const App = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Index />} />
        <Route exact path='/list' element={<List />} />
        <Route exact path='/search' element={<Search />} />
        <Route exact path='/exec_search' element={<Navigate to="/search" />} />
        <Route exact path='/detail/:book_id' element={<Detail />} />
        <Route exact path='/add' element={<Add />} />
      </Routes>
    </BrowserRouter>
  )
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from "./App";
import { SearchStrProvider } from "./components/providers/SearchStrProvider";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <SearchStrProvider>
    <App />
  </SearchStrProvider>
);

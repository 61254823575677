import { useState, useEffect } from "react";
import { useContext } from "react";
import { SearchStrContext } from "./providers/SearchStrProvider";
import { Header } from './Header';

import parse from "html-react-parser";

// 特定のエンドポイントへのリクエスト送信用ライブラリとしてaxiosを使用
import { requests, instance } from './axios';

export const Search = () => {

  const request_search = '/search';

  // 検索文字列をコンテキストから取得してJSONデータとして保持
  // 検索用JSONデータ例：{search: "スカイツリー"} 
  const contextValue = useContext(SearchStrContext);
  const searchStr = { search: contextValue.searchStr };

  // 書籍データはStateで保持
  const [ books, setBooks ] = useState([]);

  // タイトルに入力した文字列を含む書籍データを検索
  useEffect(() => {
    instance.post(request_search, searchStr)
      .then((response) => {
        setBooks(response.data);
      })
      .catch(error => console.log(error));
  }, [])

  // 書籍データを一冊ずつ取得してHTML生成
  let books_html = '';
  books.forEach((b) => {
    books_html += "<tr><td>" + b.id + '</td><td><a href="/detail/' + b.id + '">' + b.title + "</a></td><td>" + b.author + "</td><td>" + b.publisher + "</td><td>" + b.year + "</td></tr>";
  });

  if (books_html == "") {
    books_html = '<tr><td colspan="5">検索結果はありません</td></tr>';
  }

  return (
    <>
    <Header list="active"/>

    <main role="main">
      <div className="container viewpage">
        <h1>検索結果：{contextValue.searchStr}</h1>
  
        <table className="table table-striped">
          <thead>
            <tr><th>ID</th><th>書籍名</th><th>著者名</th><th>出版社</th><th>出版年</th></tr>
          </thead>
          <tbody>
{parse(books_html)}
          </tbody>
        </table>
      </div>
    </main>
    </>
  )
}

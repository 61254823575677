import { Header } from './Header';

export const Index = () => {
  return (
    <>
    <Header index="active"/>

    <main role="main">
      <div className="jumbotron">
        <div className="container">
          <h1 className="display-3">booklist03: Webサーバーへのデプロイ</h1>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <h2><a href="/">Index</a></h2>
            <p>このページです。</p>
          </div>
          <div className="col-md-3">
            <h2><a href="/list">List</a></h2>
            <p>一覧表示します。</p>
          </div>
          <div className="col-md-3">
            <h2><a href="/detail/10">Detail</a></h2>
            <p>詳細表示します。</p>
          </div>
          <div className="col-md-3">
            <h2><a href="/add">Add</a></h2>
            <p>新規追加します。</p>
          </div>
        </div>
        <hr/>
      </div>
    </main>
    </>
  );
}

import { useState, useEffect } from "react";
import { Header } from './Header';

import parse from "html-react-parser";

// 特定のエンドポイントへのリクエスト送信用ライブラリとしてaxiosを使用
import { requests, instance } from './axios';

export const List = () => {

  let books_html = '';

  // 書籍データはStateで保持
  const [ books, setBooks ] = useState([]);

  // axiosで取得し、setBooksで書籍データを取得
  useEffect(() => {
    instance.get(requests)
      .then((response) => {
        setBooks(response.data);
//      console.log(response.data);
      })
      .catch(error => console.log(error));
  }, [])

  // 書籍データを一冊ずつ取得してHTML生成
  books.forEach((b) => {
    books_html += "<tr><td>" + b.id + '</td><td><a href="/detail/' + b.id + '">' + b.title + "</a></td><td>" + b.author + "</td><td>" + b.publisher + "</td><td>" + b.year + "</td></tr>";
  });

  return (
    <>
    <Header list="active"/>

    <main role="main">
      <div className="container viewpage">
        <h1>一覧表示</h1>
  
        <table className="table table-striped">
          <thead>
            <tr><th>ID</th><th>書籍名</th><th>著者名</th><th>出版社</th><th>出版年</th></tr>
          </thead>
          <tbody>
{parse(books_html)}
          </tbody>
        </table>
      </div>
    </main>
    </>
  )
}
